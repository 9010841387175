import classNames from 'classnames';
import React from 'react';

import { AppContext } from '@jpp/context/AppContext';

import './Hamburger.scss';

interface IHamburgerProps {
  className?: string;
}

const ROOT_CLASSNAME = 'Hamburger';

const Hamburger: React.FunctionComponent<IHamburgerProps> = ({
  className
}) => {
  const { setMenuOpen, isMenuOpen } = React.useContext(AppContext);

  return (
    <button className={classNames(ROOT_CLASSNAME, className, {
      [`${ROOT_CLASSNAME}--is-active`]: isMenuOpen
    })} onClick={setMenuOpen}>
      <span className={`${ROOT_CLASSNAME}__line`} />
    </button>
  );
};

export default Hamburger;
