import React from 'react';

export enum EDirection {
  Idle = 'idle',
  Up = 'up',
  Down = 'down',
}

interface IUseScrolling {
  scrollY: number;
  scrollDirection: EDirection;
}

const useScrolling = (threshold: number = 300) => {
  const [{ scrollY, ...state }, setState] = React.useState<IUseScrolling>({
    scrollY: 0,
    scrollDirection: EDirection.Idle
  });

  const eventListeners = () => {
    setState((prevState) => {
      const currentScroll = window.pageYOffset;
      let scrollDirection = EDirection.Idle;

      if (currentScroll !== scrollY) {
        if (currentScroll < prevState.scrollY) {
          scrollDirection = EDirection.Up;
        }
        if (currentScroll > prevState.scrollY) {
          scrollDirection = EDirection.Down;
        }
      }

      if (currentScroll <= threshold) {
        scrollDirection = EDirection.Idle;
      }

      return {
        scrollY: currentScroll,
        scrollDirection
      };
    });
  };

  React.useEffect(() => {
    window.document.addEventListener('scroll', eventListeners);
    return () => {
      window.document.removeEventListener('scroll', eventListeners);
    };
  }, []);

  return {
    scrollY,
    scrollDirection: state.scrollDirection
  };
};

export default useScrolling;
