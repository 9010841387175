import classNames from 'classnames';
import React from 'react';

import Brand from '@jpp/atoms/Brand';
import Hamburger from '@jpp/atoms/Hamburger';
import { AppContext } from '@jpp/context/AppContext';

import './Header.scss';

const ROOT_CLASSNAME = 'Header';

const Header: React.FC = () => {
  const { isMenuOpen } = React.useContext(AppContext);
  return (
    <header className={classNames(ROOT_CLASSNAME, {
      [`${ROOT_CLASSNAME}--is-menu-open`]: isMenuOpen
    })}>
      <Brand className={`${ROOT_CLASSNAME}__brand`} />
      <Hamburger className={`${ROOT_CLASSNAME}__hamburger`} />
    </header>
  );
};

export default Header;
