import classNames from 'classnames';
import React from 'react';

import Link from '@jpp/atoms/Link';
import useSiteMetadata from '@jpp/hooks/useSiteMetadata';
import { EContentfulEntries } from 'common/types';

import './SocialMenu.scss';

interface ISocialMenuProps {
  className?: string;
}

const ROOT_CLASSNAME = 'SocialMenu';

const SocialMenu: React.FunctionComponent<ISocialMenuProps> = ({
  className
}) => {
  const {
    contentfulSiteSettings: {
      facebook,
      twitter,
      instagram,
      email
    }
  } = useSiteMetadata();

  const socialMenu = [
    { title: 'facebook', link: facebook },
    { title: 'twitter', link: twitter },
    { title: 'instagram', link: instagram },
    { title: 'email', link: `mailto:${email}` }
  ];

  return (
    <nav className={classNames(ROOT_CLASSNAME, className)}>
      {socialMenu.map(({ title, link }, index) => {
        if (!link) {
          return null;
        }

        return (
          <Link
            key={`${link} ${index}`}
            className={`${ROOT_CLASSNAME}__link`}
            title={title}
            link={link}
            type={EContentfulEntries.ExternalLink}
            isExternal={true}
          />
        );
      })}
    </nav>
  );
};

export default SocialMenu;
