import { graphql, useStaticQuery } from 'gatsby';
import Cookies from 'js-cookie';

import { DKHAP_SITE_WIDE_MODAL_COOKIE } from 'common/utils/constants';

export interface IUseSiteModal {
  content: Contentful.IMarkdown;
  isEnabled: boolean;
}

const useSiteModal = (): IUseSiteModal => {
  const cookie = Cookies.get(DKHAP_SITE_WIDE_MODAL_COOKIE);
  const hasCookie = !!cookie;
  const { contentfulSiteWideModal: { isEnabled, content } } = useStaticQuery(
    graphql`
        query SiteModal {
            contentfulSiteWideModal(contentfulid: {eq: "site-wide-message"}) {
                type: __typename
                isEnabled
                content {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    `,
  );

  return {
    content,
    isEnabled: hasCookie ? false : isEnabled,
  };
};

export default useSiteModal;
