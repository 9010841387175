import classNames from 'classnames';
import Cookies from 'js-cookie';
import React from 'react';

import useSiteModal from '@jpp/hooks/useSiteModal';
import WYSIWYG from '@jpp/molecules/WYSIWYG';
import { EContentfulPosition } from 'common/types';
import { DKHAP_SITE_WIDE_MODAL_COOKIE } from 'common/utils/constants';
import { daysToSeconds, getExpiryDate } from 'common/utils/time';

import ModalCore from '../ModalCore';
import './SiteWideModal.scss';

interface ISiteWideModalProps {
  className?: string;
  isVisible: boolean;
  onClose: () => void;
}

const ROOT_CLASSNAME = 'SiteWideModal';

export const SiteWideModal: React.FunctionComponent<ISiteWideModalProps> = ({
  className,
  isVisible,
  onClose
}) => {
  const { content, isEnabled } = useSiteModal();

  const handleModalToggle = () => {
    Cookies.set(DKHAP_SITE_WIDE_MODAL_COOKIE, 'true', {
      expires: getExpiryDate(daysToSeconds(7)),
      path: '/'
    });
    onClose();
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <ModalCore
      isVisible={isVisible}
      bodyOverflow={true}
      className={classNames(ROOT_CLASSNAME, className)}
      overlayOnClick={handleModalToggle}
    >
      <button
        onClick={handleModalToggle}
        className={`${ROOT_CLASSNAME}__close`}
      >
        X
      </button>

      {content && (
        <WYSIWYG
          className={`${ROOT_CLASSNAME}__content`}
          content={content}
          textAlign={EContentfulPosition.Center}
        />
      )}
    </ModalCore>
  );
};

export default SiteWideModal;
