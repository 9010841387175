import { graphql, useStaticQuery } from 'gatsby';

export interface ISiteMetadata {
  siteUrl: string;
  contentfulSiteSettings: Contentful.ISiteSettings;
}

const useSiteMetadata = (): ISiteMetadata => {
  return useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            siteUrl
          }
        }
        contentfulSiteSettings(contentfulid: {eq: "default"}) {
          type: __typename
          companyName
          mainLogo {
            ...ContentfulAsset
          }
          alternativeMainLogo {
            ...ContentfulAsset
          }
          telephone
          email
          facebook
          instagram
          twitter
          address {
            childMarkdownRemark {
              ... ContentfulMarkdownRemark
            }
          }
          openingHours {
            childMarkdownRemark {
              ... ContentfulMarkdownRemark
            }
          }
          supporters {
            ... ContentfulExternalLink
          }
        }
      }
    `
  );
};

export default useSiteMetadata;
