export const isClient = () => typeof window !== 'undefined';

export const setBodyOverflow = (addClass: boolean, className: string): void => {
  if (isClient()) {
    addClass
      ? document.body.classList.add(className)
      : document.body.classList.remove(className);
  }
  return;
};

export const VARIANT_EASE = [0.48, 0.15, 0.25, 0.96];

export const modalVariants = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: VARIANT_EASE
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: VARIANT_EASE
    }
  }
};
