import React from 'react';

// Naive implementation - in reality would want to attach
// a window or resize listener. Also use state/layoutEffect instead of ref/effect
// if this is important to know on initial client render.
// It would be safer to  return null for unmeasured states.
export const useDimensions = ({ current }: React.MutableRefObject<HTMLElement | null>) => {
  const dimensions = React.useRef({ width: 0, height: 0 });

  React.useLayoutEffect(() => {
    if (current) {
      dimensions.current.width = current?.offsetWidth;
      dimensions.current.height = current?.offsetHeight;
    }
  }, []);

  return dimensions.current;
};
