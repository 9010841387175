import React from 'react';
import "./src/assets/styles/global.scss"
import AppProvider from "./src/context/AppContext";
import DefaultLayout from "./src/layouts/Default";
import {ParallaxProvider} from "react-scroll-parallax";

export const wrapRootElement = ({element}) => {
  return (
    <AppProvider>
      <ParallaxProvider>
        <DefaultLayout>
          {element}
        </DefaultLayout>
      </ParallaxProvider>
    </AppProvider>
  );
}
