import classNames from 'classnames';
import React from 'react';

import Portal from '@jpp/atoms/Portal';
import { setBodyOverflow } from 'common/utils';

import './ModalCore.scss';

const ROOT_CLASSNAME = 'ModalCore';
export const MODAL_ROOT_ID = 'js-modal-root';
export const MODAL_OVERLAY_HIDDEN_CLASSNAME = `${ROOT_CLASSNAME}__body-overflow-hidden`;

export interface IModalCoreProps {
  className?: string;
  isVisible: boolean;
  bodyOverflow?: boolean;
  overlayOnClick?: () => void;
}

export const ModalRoot: React.FunctionComponent = () => <div id={MODAL_ROOT_ID} />;

const ModalCore: React.FunctionComponent<IModalCoreProps> = ({
  isVisible,
  className,
  children,
  bodyOverflow = true,
  overlayOnClick
}) => {
  const selector = `#${MODAL_ROOT_ID}`;

  if (!bodyOverflow) {
    setBodyOverflow(isVisible, MODAL_OVERLAY_HIDDEN_CLASSNAME);
  }

  const handleOverlayOnClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (overlayOnClick) {
      overlayOnClick();
    }
  };

  React.useEffect(() => {
    return () => {
      setBodyOverflow(false, MODAL_OVERLAY_HIDDEN_CLASSNAME);
    };
  }, []);

  return (
    <Portal selector={selector}>
      <div className={ROOT_CLASSNAME}>
        <button
          className={classNames(`${ROOT_CLASSNAME}__overlay`, {
            [`${ROOT_CLASSNAME}__overlay--no-pointer-events`]: !overlayOnClick
          })}
          onClick={handleOverlayOnClose}
        />
        <div className={classNames(`${ROOT_CLASSNAME}__body`, className, {
          [`${ROOT_CLASSNAME}__body--is-visible`]: isVisible
        })}>
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default ModalCore;
