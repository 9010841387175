import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import { EContentfulEntries } from 'common/types';

import './Link.scss';

export interface ILinkProps extends Contentful.IMenuLink {
  className?: string;
  onClick?: () => void;
}

const ROOT_CLASSNAME = 'Link';

const Link: React.FunctionComponent<ILinkProps> = ({
  type,
  title,
  children,
  isExternal,
  onClick,
  className,
  ...props
}) => {
  const defaultProps = {
    className: classNames(ROOT_CLASSNAME, className),
    children: children ?? title,
    onClick
  };
  const link = !props.link.startsWith('/') && !isExternal ? `/${props.link}` : props.link;

  if (type === EContentfulEntries.ExternalLink) {
    return (
      <a
        {...defaultProps}
        href={link}
        target={isExternal ? '_blank' : undefined}
      />
    );
  }

  return (
    <GatsbyLink
      {...defaultProps}
      to={link}
      activeClassName={`${ROOT_CLASSNAME}--is-active`}
    />
  );
};

export default Link;
