import { PageProps } from 'gatsby';

export enum EContentfulEntries {
  SiteSettings = 'ContentfulSiteSettings',
  ContentBlock = 'ContentfulWidgetContentBlock',
  PageHero = 'ContentfulWidgetPageHero',
  ImageScroller = 'ContentfulWidgetImageScroller',
  HomeHero = 'ContentfulWidgetHomeHero',
  InternalLink = 'ContentfulInternalLink',
  ExternalLink = 'ContentfulExternalLink',
  TextBlock = 'ContentfulWidgetTextBlock',
  Map = 'ContentfulWidgetMap',
  Columns = 'ContentfulWidgetColumns',
  Gallery = 'ContentfulWidgetGallery',
}

export enum ETheme {
  Brand = 'brand',
  Alpha = 'alpha'
}

export enum EParallaxSize {
  Small = 'sm',
  Medium = 'medium',
  Large = 'large'
}

export enum EContentfulPosition {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right'
}

interface IPageProps {
  className?: string;
}

export interface IPageContextProps {
  title: string;
  slug: string;
}

export type TPage = IPageProps & Omit<PageProps<Contentful.IPageDataProps, IPageContextProps>, 'children'>;
