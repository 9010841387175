export const secondsToDays = (seconds: number) => seconds / 24 / 60 / 60;
export const secondsToMilliseconds = (seconds: number) => seconds * 1000;
export const millisecondsToDays = (milliseconds: number) =>
  millisecondsToSeconds(milliseconds) / 60 / 60 / 24;
export const millisecondsToSeconds = (milliseconds: number) =>
  milliseconds / 1000;
export const daysToSeconds = (days: number) => days * 60 * 60 * 24;
export const daysToMilliseconds = (days: number) => daysToSeconds(days) * 1000;

export const getExpiryDate = (
  expiresInSeconds: number = daysToSeconds(365)
): Date => {
  const lifetimeInMilliseconds = secondsToMilliseconds(expiresInSeconds);
  const expiresAt = Date.now() + lifetimeInMilliseconds;

  return new Date(expiresAt);
};
