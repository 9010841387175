import React from 'react';
import ReactDOM from 'react-dom';

interface IPortalProps {
  className?: string;
  selector: string;
}

class Portal extends React.PureComponent<IPortalProps> {
  element: HTMLElement | null = null;

  componentDidMount = () => {
    this.element = document.querySelector(this.props.selector);
    this.forceUpdate();
  };

  render() {
    if (!this.element) {
      return null;
    }

    return ReactDOM.createPortal(this.props.children, this.element);
  }

}

export default Portal;
