import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import useScrolling from '@jpp/hooks/useScrolling';
import useToggle from '@jpp/hooks/useToggle';
import Footer from '@jpp/organisms/Footer';
import Header from '@jpp/organisms/Header';
import { ModalRoot } from '@jpp/organisms/Modal';
import SiteWideModal from '@jpp/organisms/Modal/SiteWideModal';
import OffCanvas from '@jpp/organisms/OffCanvas';

import './Default.scss';

interface IDefaultProps {
  className?: string;
}

const ROOT_CLASSNAME = 'DefaultLayout';

const Default: React.FunctionComponent<IDefaultProps> = ({
  className,
  children
}) => {
  const { scrollY, scrollDirection } = useScrolling();
  const pageVariants = {
    initial: { opacity: 0, x: -10 },
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.2,
        duration: 0.3,
        ease: [0.48, 0.15, 0.25, 0.96]
      }
    }
  };

  const [isVisible, toggleIsVisible] = useToggle(false);

  return (
    <div className={classNames(ROOT_CLASSNAME, className, {
      [`${ROOT_CLASSNAME}--is-scrolling`]: !!scrollY,
      [`${ROOT_CLASSNAME}--is-scrolling-${scrollDirection}`]: !!scrollDirection
    })}>
      <Header />
      <OffCanvas />
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={pageVariants}
      >
        <main className={`${ROOT_CLASSNAME}__main`}>
          {children}
        </main>
        <SiteWideModal
          isVisible={isVisible}
          onClose={toggleIsVisible}
        />
      </motion.div>
      <Footer />
      <ModalRoot />
    </div>
  );
};

export default Default;
