import noop from 'lodash/noop';
import React from 'react';

import useSiteMetadata, { ISiteMetadata } from '@jpp/hooks/useSiteMetadata';
import useToggle from '@jpp/hooks/useToggle';
import { isClient } from 'common/utils';

interface IAppContext extends ISiteMetadata {
  isMenuOpen: boolean;
  setMenuOpen: () => void;
}

const defaultValues: IAppContext = {
  siteUrl: '',
  contentfulSiteSettings: {} as Contentful.ISiteSettings,
  isMenuOpen: false,
  setMenuOpen: noop
};

export const AppContext = React.createContext<IAppContext>(defaultValues);

const AppProvider: React.FunctionComponent = ({
  children
}) => {
  const [isMenuOpen, setMenuOpenState] = useToggle();
  const siteMetadata = useSiteMetadata();

  const setMenuOpen = () => {
    if (isClient()) {
      window.document.body.classList.toggle('body--overflow-hidden');
    }
    setMenuOpenState();
  };

  return (
    <AppContext.Provider
      value={{
        ...siteMetadata,
        isMenuOpen,
        setMenuOpen
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
