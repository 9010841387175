import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { EHeadingPriority } from '@jpp/atoms/Heading/types';
import Link from '@jpp/atoms/Link';
import { AppContext } from '@jpp/context/AppContext';
import { useDimensions } from '@jpp/hooks/useDimensions';
import useMenu, { EMenuId } from '@jpp/hooks/useMenu';
import useSiteMetadata from '@jpp/hooks/useSiteMetadata';
import WYSIWYG from '@jpp/molecules/WYSIWYG';

import './OffCanvas.scss';

interface IOffCanvasProps {
  className?: string;
}

const ROOT_CLASSNAME = 'OffCanvas';

const OffCanvas: React.FunctionComponent<IOffCanvasProps> = ({
  className
}) => {
  const { isMenuOpen, setMenuOpen } = React.useContext(AppContext);
  const {
    contentfulSiteSettings: {
      address,
      openingHours,
      telephone,
      email
    }
  } = useSiteMetadata();
  const menu = useMenu(EMenuId.PrimaryMenu);
  const containerRef = React.useRef(null);
  const { height } = useDimensions(containerRef);

  const sidebar = {
    open: (sidebarHeight = 1000) => ({
      clipPath: `circle(${sidebarHeight * 2 + 200}px at 48px 48px)`,
      backgroundColor: 'white',
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: 'circle(16px at 48px 48px)',
      transition: {
        delay: 0.2,
        type: 'spring',
        stiffness: 400,
        damping: 40
      }
    }
  };

  const listItemVariants = {
    open: {
      y: 0,
      opacity: 1,
      delay: 0.2,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  const listVariants = {
    open: {
      transition: { staggerChildren: 0.10, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  return (
    <aside
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--is-active`]: isMenuOpen
      })}
    >
      <div className={`${ROOT_CLASSNAME}__menu-wrapper`}>
        <motion.nav
          initial={false}
          animate={isMenuOpen ? 'open' : 'closed'}
          custom={height}
          ref={containerRef}
          className={`${ROOT_CLASSNAME}__nav`}
        >
          <motion.div className={`${ROOT_CLASSNAME}__overlay`} variants={sidebar} />
          <motion.ul className={`${ROOT_CLASSNAME}__menu`} variants={listVariants}>
            {menu.map((menuItem, index) => {
              return (
                <motion.li
                  key={index}
                  className={`${ROOT_CLASSNAME}__menu-item`}
                  variants={listItemVariants}
                >
                  <Link onClick={setMenuOpen} {...menuItem} />
                  {menuItem.descriptor && (
                    <span className={`${ROOT_CLASSNAME}__descriptor`} children={menuItem.descriptor} />
                  )}
                </motion.li>
              );
            })}
          </motion.ul>
        </motion.nav>
      </div>

      <div className={`${ROOT_CLASSNAME}__contact-wrapper`}>
        <Heading
          priority={EHeadingPriority.Two}
          className={`${ROOT_CLASSNAME}__heading`}
          children="Contact Information"
        />
        <WYSIWYG className={`${ROOT_CLASSNAME}__content`} content={address} />
        <p className={`${ROOT_CLASSNAME}__copy`}>
          <strong>Telephone:</strong> {telephone}
        </p>
        <p className={`${ROOT_CLASSNAME}__copy`}>
          <strong>Email:</strong> <a href={`mailto:${email}`} className={`${ROOT_CLASSNAME}__link`}>{email}</a>
        </p>
        <Heading
          priority={EHeadingPriority.Two}
          className={`${ROOT_CLASSNAME}__heading mt-2`}
          children="Opening Hours"
        />
        <WYSIWYG
          className={`${ROOT_CLASSNAME}__content ${ROOT_CLASSNAME}__content--opening-hours`}
          content={openingHours}
        />
      </div>
    </aside>
  );
};

export default OffCanvas;
