import React from 'react';

import Brand from '@jpp/atoms/Brand';
import Heading from '@jpp/atoms/Heading';
import { EHeadingPriority } from '@jpp/atoms/Heading/types';
import Link from '@jpp/atoms/Link';
import useMenu, { EMenuId } from '@jpp/hooks/useMenu';
import useSiteMetadata from '@jpp/hooks/useSiteMetadata';
import SocialMenu from '@jpp/molecules/SocialMenu';
import WYSIWYG from '@jpp/molecules/WYSIWYG';

import './Footer.scss';

const ROOT_CLASSNAME = 'Footer';

const Footer: React.FC = () => {
  const {
    contentfulSiteSettings: {
      address,
      email,
      supporters = [],
      telephone
    }
  } = useSiteMetadata();

  const footerMenu = useMenu(EMenuId.FooterMenu);
  const galleryMenu = useMenu(EMenuId.GalleryMenu);

  return (
    <footer className={ROOT_CLASSNAME}>
      <div className={`${ROOT_CLASSNAME}__top`}>
        <div className={`${ROOT_CLASSNAME}__container`}>
          <div className={`${ROOT_CLASSNAME}__column ${ROOT_CLASSNAME}__column--logo`}>
            <Brand className={`${ROOT_CLASSNAME}__logo`} isAlternative={true} />
          </div>

          <div className={`${ROOT_CLASSNAME}__column`}>
            <Heading
              priority={EHeadingPriority.Four}
              className={`${ROOT_CLASSNAME}__heading`}
            >
              About
            </Heading>

            <ul className={`${ROOT_CLASSNAME}__list`}>
              {footerMenu.map((menuItem, index) => (
                <li key={`${menuItem.title} ${index}`} className={`${ROOT_CLASSNAME}__item`}>
                  <Link className={`${ROOT_CLASSNAME}__item-link`} {...menuItem} />
                </li>
              ))}
            </ul>
          </div>

          <div className={`${ROOT_CLASSNAME}__column`}>
            <Heading
              priority={EHeadingPriority.Four}
              className={`${ROOT_CLASSNAME}__heading`}
            >
              Gallery
            </Heading>

            <ul className={`${ROOT_CLASSNAME}__list`}>
              {galleryMenu.map((menuItem, index) => (
                <li key={`${menuItem.title} ${index}`} className={`${ROOT_CLASSNAME}__item`}>
                  <Link className={`${ROOT_CLASSNAME}__item-link`} {...menuItem} />
                </li>
              ))}
            </ul>
          </div>

          <div className={`${ROOT_CLASSNAME}__column`}>
            <Heading
              priority={EHeadingPriority.Four}
              className={`${ROOT_CLASSNAME}__heading`}
            >
              Social
            </Heading>
            <SocialMenu className={`${ROOT_CLASSNAME}__social`} />
          </div>

          <div className={`${ROOT_CLASSNAME}__column`}>
            <Heading
              priority={EHeadingPriority.Four}
              className={`${ROOT_CLASSNAME}__heading`}
            >
              Get in touch
            </Heading>
            <WYSIWYG
              className={`${ROOT_CLASSNAME}__content`}
              content={address}
            />
            <p className={`${ROOT_CLASSNAME}__copy`}>
              <strong>Telephone:</strong> {telephone}
            </p>
            <p className={`${ROOT_CLASSNAME}__copy`}>
              <strong>Email:</strong> <a href={`mailto:${email}`} className={`${ROOT_CLASSNAME}__link`}>{email}</a>
            </p>
          </div>
        </div>
      </div>

      <div className={`${ROOT_CLASSNAME}__legal`}>
        <div className={`${ROOT_CLASSNAME}__container ${ROOT_CLASSNAME}__container--legal`}>
          <div className={`${ROOT_CLASSNAME}__column ${ROOT_CLASSNAME}__column--full`}>
            <p className={`${ROOT_CLASSNAME}__legal`}>
              &copy; {new Date().getFullYear()}, Dog Kennel Hill Adventure Playground | DKHAP | Website by <a
              href="https://www.pixeltwist.io" className={`${ROOT_CLASSNAME}__link`}>Pixel Twist</a> (a trading name of
              JPPdesigns).
            </p>
          </div>
        </div>
      </div>

      <div className={`${ROOT_CLASSNAME}__bottom`}>
        <div className={`${ROOT_CLASSNAME}__container`}>
          <div className={`${ROOT_CLASSNAME}__column`}>
            <Heading
              priority={EHeadingPriority.Four}
              className={`${ROOT_CLASSNAME}__heading`}
            >
              Our&nbsp;Supporters:
            </Heading>
          </div>

          <div className={`${ROOT_CLASSNAME}__column ${ROOT_CLASSNAME}__column--big`}>
            <nav className={`${ROOT_CLASSNAME}__nav ${ROOT_CLASSNAME}__nav--supporters`}>
              {supporters.map((supporter, index) => (
                <Link
                  key={`${supporter.title} ${index}`}
                  {...supporter}
                  className={`${ROOT_CLASSNAME}__nav-link`}
                />
              ))}
            </nav>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
