import classNames from 'classnames';
import Img from 'gatsby-image';
import GatsbyLink from 'gatsby-link';
import React from 'react';

import { AppContext } from '@jpp/context/AppContext';
import useSiteMetadata from '@jpp/hooks/useSiteMetadata';

import './Brand.scss';

interface IBrandProps {
  className?: string;
  isAlternative?: boolean;
}

const ROOT_CLASSNAME = 'Brand';

const Brand: React.FunctionComponent<IBrandProps> = ({
  className,
  isAlternative = false
}) => {
  const { contentfulSiteSettings: { mainLogo, alternativeMainLogo } } = useSiteMetadata();
  const { setMenuOpen, isMenuOpen } = React.useContext(AppContext);
  const onClick = isMenuOpen ? setMenuOpen : undefined;

  const image = isAlternative ? alternativeMainLogo : mainLogo;

  return (
    <div
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--is-alternative`]: !!isAlternative,
        [`${ROOT_CLASSNAME}--menu-is-open`]: isMenuOpen
      })}
    >
      <GatsbyLink to="/" onClick={onClick}>
        <Img {...image} className={`${ROOT_CLASSNAME}__image`} />
      </GatsbyLink>

      <div
        className={classNames(`${ROOT_CLASSNAME}__circle`, {
          [`${ROOT_CLASSNAME}__circle--is-active`]: isMenuOpen
        })}
      />
    </div>
  );
};

export default Brand;
