import classNames from 'classnames';
import React from 'react';

import { EContentfulPosition } from 'common/types';

import './WYSIWYG.scss';

interface IWYSIWYGProps {
  content: Contentful.IMarkdown;
  className?: string;
  textAlign?: EContentfulPosition;
}

const ROOT_CLASSNAME = 'WYSIWYG';

const WYSIWYG: React.FunctionComponent<IWYSIWYGProps> = ({
  className,
  content,
  textAlign = EContentfulPosition.Left
}) => (
  <div
    className={classNames(
      ROOT_CLASSNAME,
      className,
      `${ROOT_CLASSNAME}--text-align-${textAlign?.toLowerCase()}`
    )}
    dangerouslySetInnerHTML={{ __html: content?.childMarkdownRemark?.html }}
  />
);

export default WYSIWYG;
