import { graphql, useStaticQuery } from 'gatsby';

export enum EMenuId {
  PrimaryMenu = 'primary-menu',
  TermsMenu = 'terms-menu',
  FooterMenu = 'footer-menu',
  GalleryMenu = 'gallery-menu',
  SupportersMenu = 'supporters-menu'
}

const useMenu = (menuId: EMenuId): Contentful.IMenuLink[] => {
  const { allContentfulMenu: { edges } } = useStaticQuery(
    graphql`
      query Menu {
        allContentfulMenu {
          edges {
            node {
              menuId
              links {
                ... ContentfulInternalLink
                ... ContentfulExternalLink
              }
            }
          }
        }
      }
    `
  );
  const findMenu = edges.find(menu => menu?.node?.menuId === menuId);
  return findMenu?.node?.links || [];
};

export default useMenu;
