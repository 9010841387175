import classNames from 'classnames';
import React from 'react';

import './Heading.scss';
import { EHeadingPriority } from './types';

interface IHeadingProps {
  className?: string;
  priority?: EHeadingPriority;
}

const ROOT_CLASSNAME = 'Heading';

const Heading: React.FunctionComponent<IHeadingProps> = ({
  className,
  priority = EHeadingPriority.None,
  children
}) => {
  const Tag = `${priority}` as unknown as React.ComponentType<any>;

  return (
    <Tag
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${priority}`
      )}
      children={children}
    />
  );
};

export default Heading;
